html,
body,
#root {
  height: 100%;
  position: relative;
  font-family: 'HKGrotesk-Regular', sans-serif;
}

* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

input:-internal-autofill-selected {
  background-color: transparent !important;
}

div[class*='PopupComponent__Content'],
div[class*='Popup__PopupContent'] {
  font-family: 'HKGrotesk-Regular', sans-serif;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  appearance: auto !important;
  color: transparent !important;
  background-color: transparent !important;
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

@font-face {
  font-family: 'HKGrotesk-Light';
  font-weight: normal;
  src: url(./fonts/HKGrotesk-Light.woff2) format('woff2'),
    url(./fonts/HKGrotesk-Light.woff) format('woff'),
    url(./fonts/HKGrotesk-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'HKGrotesk-Regular';
  font-weight: normal;
  src: url(./fonts/HKGrotesk-Regular.woff2) format('woff2'),
    url(./fonts/HKGrotesk-Regular.woff) format('woff'),
    url(./fonts/HKGrotesk-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'HKGrotesk-Black';
  font-weight: bold;
  src: url(./fonts/HKGrotesk-Black.woff2) format('woff2'),
    url(./fonts/HKGrotesk-Black.woff) format('woff'),
    url(./fonts/HKGrotesk-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Regular';
  font-weight: normal;
  src: url(./fonts/Roboto-Regular.woff2) format('woff2'),
    url(./fonts/Roboto-Regular.woff) format('woff'),
    url(./fonts/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto-Black';
  font-weight: bold;
  src: url(./fonts/Roboto-Black.woff2) format('woff2'),
    url(./fonts/Roboto-Black.woff) format('woff'),
    url(./fonts/Roboto-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'Libre Franklin-Black';
  font-weight: black;
  src: url(./fonts/LibreFranklin-900.ttf) format('truetype');
}

@font-face {
  font-family: 'Libre Franklin-ExtraLight';
  font-weight: light;
  src: url(./fonts/LibreFranklin-ExtraLight.ttf) format('truetype');
}
@font-face {
  font-family: 'Libre Franklin-ExtraLightItalic';
  font-weight: light;
  src: url(./fonts/LibreFranklin-200.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: 'HKGrotesk-Regular', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Modal style*/
.ReactModal__Content {
  max-width: 100%;
  overflow-x: hidden;

  scrollbar-color: #ffd60080 transparent;
  overflow-y: auto;
}

.ReactModal__Content::-webkit-scrollbar {
  width: 10px;
}

.ReactModal__Content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #ffd60080;
}

.ReactModal__Content::-webkit-scrollbar-corner {
  background-color: #000;
}

/* 

Placeholder

*/

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
  background: rgba(155, 155, 155, 0.75);
}
.placeholder.btn::before {
  display: inline-block;
  content: '';
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  -webkit-mask-image: linear-gradient(
    130deg,
    #000 55%,
    rgba(0, 0, 0, 0.8) 75%,
    #000 95%
  );
  mask-image: linear-gradient(
    130deg,
    #000 55%,
    rgba(0, 0, 0, 0.8) 75%,
    #000 95%
  );
  -webkit-mask-size: 200% 100%;
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0%;
    mask-position: -200% 0%;
  }
}

/* Cookie Styles - Cookiebot */

#CookiebotWidget {
  display: none;
}

#cookie-declaration {
  color: #fff;
  font-family: 'HKGrotesk-Light';
  color: #ffffff;
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
}

#CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
  border: 2px solid #ffd600 !important;
}

#CybotCookiebotDialogNav
  .CybotCookiebotDialogNavItemLink.CybotCookiebotDialogActive {
  border-bottom: 1px solid #ffd600 !important;
  color: #ffd600 !important;
}

#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonCustomize,
#CybotCookiebotDialogFooter
  #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection {
  border-color: #ffd600 !important;
}

#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonAccept,
#CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonAccept,
#CybotCookiebotDialogFooter
  #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll {
  border-color: #ffd600 !important;
  background-color: #ffd600 !important;
}

#CybotCookiebotDialogNav .CybotCookiebotDialogNavItemLink:hover {
  color: #ffd600 !important;
}

/* Intercom - Styles */

.intercom-namespace .intercom-o91o14 {
  bottom: 50px;
  right: 50px;
}

/* Columns */

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.fs-01 {
  font-size: 1px;
}

.fs-02 {
  font-size: 2px;
}

.fs-14 {
  font-size: 14px;
}
.fs-18 {
  font-size: 18px;
}

.fs-36 {
  font-size: 36px;
}

.fs-48 {
  font-size: 48px;
}

.lh-1 {
  line-height: 1px;
}

.lh-2 {
  line-height: 2px;
}

.lh-5 {
  line-height: 5px;
}

.lh-14 {
  line-height: 14px;
}

.lh-20 {
  line-height: 20px;
}
.lh-24 {
  line-height: 24px;
}

.lh-26 {
  line-height: 26px;
}

.lh-28 {
  line-height: 28px;
}

.lh-30 {
  line-height: 30px;
}

.lh-32 {
  line-height: 32px;
}

.lh-36 {
  line-height: 36px;
}

.lh-40 {
  line-height: 40px;
}

.lh-42 {
  line-height: 42px;
}

.lh-44 {
  line-height: 44px;
}

.lh-48 {
  line-height: 48px;
}

.lh-80 {
  line-height: 55px;
}

.apple-pay-button {
  width: 350px;
  height: 45px;
  display: inline-block;
  -webkit-appearance: -apple-pay-button;
  cursor: pointer;
}

.apple-pay-button-with-text > * {
  display: none;
}

.apple-pay-button-black-with-text {
  -apple-pay-button-style: black;
}

.apple-pay-button-white-with-text {
  -apple-pay-button-style: white-outline;
  border-radius: 50px;
}

.apple-pay-button-white-with-line-with-text {
  -apple-pay-button-style: white-outline;
}

.apple-pay-button-text-buy {
  -apple-pay-button-type: buy;
}

.apple-pay-button-text-pay {
  -apple-pay-button-type: pay;
}

/* For mobile devices */

@media only screen and (max-width: 600px) {
  .apple-pay-button {
    padding: 0 60px;
    max-width: 350px;
    margin-left: -5px;
  }
}
