
.waveform-xs {
  height: 21px;
}

.waveform-sm {
  height: 31px;
}

.waveform-md {
  height: 35px;
}

.waveform-lg {
  height: 40px;
}

.waveform-xl {
  height: 50px;
}

@media (min-width: 768px) {
  .waveform-xs {
    height: 32px;
  }
  
  .waveform-sm {
    height: 48px;
  }
  
  .waveform-md {
    height: 52px;
  }
  
  .waveform-lg {
    height: 60px;
  }
  
  .waveform-xl {
    height: 76px;
  }
}
