.terms {
  font-family: 'HKGrotesk-Regular';
  line-height: 18px;

  line-height: 18px !important;
  p {
    line-height: 18px;
    display: block !important;
    margin-block-start: 0.5em !important;
    margin-block-end: 0.5em !important;
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;

    line-height: 18px !important;
    padding-left: -15px !important;
  }

  ol.lst-kix_x65qu51ectud-0 {
    list-style-type: none;
  }

  ol.lst-kix_x65qu51ectud-7 {
    list-style-type: none;
  }

  ol.lst-kix_x65qu51ectud-8 {
    list-style-type: none;
  }

  ol.lst-kix_x65qu51ectud-5 {
    list-style-type: none;
  }

  ol.lst-kix_x65qu51ectud-6.start {
    counter-reset: lst-ctn-kix_x65qu51ectud-6 0;
  }

  ol.lst-kix_x65qu51ectud-6 {
    list-style-type: none;
  }

  ol.lst-kix_x65qu51ectud-3 {
    list-style-type: none;
  }

  .lst-kix_yla40c1y0ok7-6 > li {
    counter-increment: lst-ctn-kix_yla40c1y0ok7-6;
  }

  ol.lst-kix_x65qu51ectud-1 {
    list-style-type: none;
  }

  ol.lst-kix_mjfyf9huakgj-8.start {
    counter-reset: lst-ctn-kix_mjfyf9huakgj-8 0;
  }

  ol.lst-kix_x65qu51ectud-2 {
    list-style-type: none;
  }

  .lst-kix_x65qu51ectud-2 > li {
    counter-increment: lst-ctn-kix_x65qu51ectud-2;
  }

  .lst-kix_o7rcp3uhhhar-0 > li {
    counter-increment: lst-ctn-kix_o7rcp3uhhhar-0;
  }

  .lst-kix_yla40c1y0ok7-5 > li {
    counter-increment: lst-ctn-kix_yla40c1y0ok7-5;
  }

  .lst-kix_x65qu51ectud-1 > li {
    counter-increment: lst-ctn-kix_x65qu51ectud-1;
  }

  ol.lst-kix_yla40c1y0ok7-3.start {
    counter-reset: lst-ctn-kix_yla40c1y0ok7-3 0;
  }

  ol.lst-kix_mjfyf9huakgj-0.start {
    counter-reset: lst-ctn-kix_mjfyf9huakgj-0 0;
  }

  .lst-kix_o7rcp3uhhhar-7 > li:before {
    content: '' counter(lst-ctn-kix_o7rcp3uhhhar-7, decimal) '. ';
  }

  .lst-kix_o7rcp3uhhhar-6 > li:before {
    content: '' counter(lst-ctn-kix_o7rcp3uhhhar-6, decimal) '. ';
  }

  .lst-kix_o7rcp3uhhhar-8 > li:before {
    content: '' counter(lst-ctn-kix_o7rcp3uhhhar-8, decimal) '. ';
  }

  .lst-kix_o7rcp3uhhhar-5 > li:before {
    content: '' counter(lst-ctn-kix_o7rcp3uhhhar-5, decimal) '. ';
  }

  .lst-kix_yla40c1y0ok7-4 > li:before {
    content: '\0025cf  ';
  }

  ol.lst-kix_x65qu51ectud-3.start {
    counter-reset: lst-ctn-kix_x65qu51ectud-3 0;
  }

  .lst-kix_yla40c1y0ok7-5 > li:before {
    content: '' counter(lst-ctn-kix_yla40c1y0ok7-5, decimal) '. ';
  }

  .lst-kix_yla40c1y0ok7-7 > li:before {
    content: '' counter(lst-ctn-kix_yla40c1y0ok7-7, decimal) '. ';
  }

  ol.lst-kix_o7rcp3uhhhar-1.start {
    counter-reset: lst-ctn-kix_o7rcp3uhhhar-1 0;
  }

  .lst-kix_yla40c1y0ok7-6 > li:before {
    content: '' counter(lst-ctn-kix_yla40c1y0ok7-6, decimal) '. ';
  }

  ol.lst-kix_yla40c1y0ok7-6.start {
    counter-reset: lst-ctn-kix_yla40c1y0ok7-6 0;
  }

  .lst-kix_o7rcp3uhhhar-0 > li:before {
    content: '' counter(lst-ctn-kix_o7rcp3uhhhar-0, decimal) '. ';
  }

  .lst-kix_o7rcp3uhhhar-1 > li:before {
    content: '' counter(lst-ctn-kix_o7rcp3uhhhar-1, lower-latin) '. ';
  }

  .lst-kix_yla40c1y0ok7-8 > li:before {
    content: '' counter(lst-ctn-kix_yla40c1y0ok7-8, decimal) '. ';
  }

  .lst-kix_o7rcp3uhhhar-2 > li {
    counter-increment: lst-ctn-kix_o7rcp3uhhhar-2;
  }

  .lst-kix_o7rcp3uhhhar-3 > li:before {
    content: '' counter(lst-ctn-kix_o7rcp3uhhhar-3, upper-latin) '. ';
  }

  .lst-kix_x65qu51ectud-1 > li:before {
    content: '' counter(lst-ctn-kix_x65qu51ectud-1, lower-latin) '. ';
  }

  .lst-kix_o7rcp3uhhhar-2 > li:before {
    content: '' counter(lst-ctn-kix_o7rcp3uhhhar-2, lower-roman) '. ';
  }

  .lst-kix_o7rcp3uhhhar-4 > li:before {
    content: '\0025cf  ';
  }

  .lst-kix_x65qu51ectud-0 > li:before {
    content: '' counter(lst-ctn-kix_x65qu51ectud-0, decimal) '. ';
  }

  .lst-kix_mjfyf9huakgj-7 > li {
    counter-increment: lst-ctn-kix_mjfyf9huakgj-7;
  }

  .lst-kix_mjfyf9huakgj-1 > li {
    counter-increment: lst-ctn-kix_mjfyf9huakgj-1;
  }

  ol.lst-kix_mjfyf9huakgj-3.start {
    counter-reset: lst-ctn-kix_mjfyf9huakgj-3 0;
  }

  ol.lst-kix_o7rcp3uhhhar-7 {
    list-style-type: none;
  }

  .lst-kix_mjfyf9huakgj-8 > li {
    counter-increment: lst-ctn-kix_mjfyf9huakgj-8;
  }

  ol.lst-kix_o7rcp3uhhhar-8 {
    list-style-type: none;
  }

  .lst-kix_mjfyf9huakgj-2 > li {
    counter-increment: lst-ctn-kix_mjfyf9huakgj-2;
  }

  ol.lst-kix_x65qu51ectud-8.start {
    counter-reset: lst-ctn-kix_x65qu51ectud-8 0;
  }

  .lst-kix_yla40c1y0ok7-3 > li:before {
    content: '' counter(lst-ctn-kix_yla40c1y0ok7-3, upper-latin) '. ';
  }

  ol.lst-kix_o7rcp3uhhhar-7.start {
    counter-reset: lst-ctn-kix_o7rcp3uhhhar-7 0;
  }

  ol.lst-kix_x65qu51ectud-0.start {
    counter-reset: lst-ctn-kix_x65qu51ectud-0 0;
  }

  .lst-kix_yla40c1y0ok7-2 > li:before {
    content: '' counter(lst-ctn-kix_yla40c1y0ok7-2, lower-roman) '. ';
  }

  .lst-kix_yla40c1y0ok7-1 > li:before {
    content: '' counter(lst-ctn-kix_yla40c1y0ok7-1, lower-latin) '. ';
  }

  .lst-kix_o7rcp3uhhhar-1 > li {
    counter-increment: lst-ctn-kix_o7rcp3uhhhar-1;
  }

  .lst-kix_o7rcp3uhhhar-7 > li {
    counter-increment: lst-ctn-kix_o7rcp3uhhhar-7;
  }

  .lst-kix_yla40c1y0ok7-0 > li:before {
    content: '' counter(lst-ctn-kix_yla40c1y0ok7-0, decimal) '. ';
  }

  .lst-kix_yla40c1y0ok7-1 > li {
    counter-increment: lst-ctn-kix_yla40c1y0ok7-1;
  }

  ol.lst-kix_x65qu51ectud-1.start {
    counter-reset: lst-ctn-kix_x65qu51ectud-1 0;
  }

  ol.lst-kix_x65qu51ectud-7.start {
    counter-reset: lst-ctn-kix_x65qu51ectud-7 0;
  }

  ol.lst-kix_o7rcp3uhhhar-0.start {
    counter-reset: lst-ctn-kix_o7rcp3uhhhar-0 0;
  }

  ul.lst-kix_o7rcp3uhhhar-4 {
    list-style-type: none;
  }

  ol.lst-kix_o7rcp3uhhhar-6.start {
    counter-reset: lst-ctn-kix_o7rcp3uhhhar-6 0;
  }

  .lst-kix_x65qu51ectud-6 > li {
    counter-increment: lst-ctn-kix_x65qu51ectud-6;
  }

  .lst-kix_yla40c1y0ok7-7 > li {
    counter-increment: lst-ctn-kix_yla40c1y0ok7-7;
  }

  ol.lst-kix_mjfyf9huakgj-2.start {
    counter-reset: lst-ctn-kix_mjfyf9huakgj-2 0;
  }

  .lst-kix_x65qu51ectud-3 > li {
    counter-increment: lst-ctn-kix_x65qu51ectud-3;
  }

  .lst-kix_x65qu51ectud-0 > li {
    counter-increment: lst-ctn-kix_x65qu51ectud-0;
  }

  .lst-kix_yla40c1y0ok7-0 > li {
    counter-increment: lst-ctn-kix_yla40c1y0ok7-0;
  }

  ol.lst-kix_mjfyf9huakgj-5 {
    list-style-type: none;
  }

  ol.lst-kix_mjfyf9huakgj-3 {
    list-style-type: none;
  }

  ol.lst-kix_mjfyf9huakgj-2 {
    list-style-type: none;
  }

  ol.lst-kix_mjfyf9huakgj-8 {
    list-style-type: none;
  }

  ol.lst-kix_mjfyf9huakgj-7 {
    list-style-type: none;
  }

  ol.lst-kix_o7rcp3uhhhar-2.start {
    counter-reset: lst-ctn-kix_o7rcp3uhhhar-2 0;
  }

  ol.lst-kix_mjfyf9huakgj-6 {
    list-style-type: none;
  }

  ol.lst-kix_mjfyf9huakgj-1 {
    list-style-type: none;
  }

  ol.lst-kix_mjfyf9huakgj-0 {
    list-style-type: none;
  }

  ol.lst-kix_yla40c1y0ok7-2.start {
    counter-reset: lst-ctn-kix_yla40c1y0ok7-2 0;
  }

  ol.lst-kix_x65qu51ectud-2.start {
    counter-reset: lst-ctn-kix_x65qu51ectud-2 0;
  }

  .lst-kix_mjfyf9huakgj-5 > li {
    counter-increment: lst-ctn-kix_mjfyf9huakgj-5;
  }

  .lst-kix_o7rcp3uhhhar-6 > li {
    counter-increment: lst-ctn-kix_o7rcp3uhhhar-6;
  }

  ul.lst-kix_yla40c1y0ok7-4 {
    list-style-type: none;
  }

  ol.lst-kix_yla40c1y0ok7-5.start {
    counter-reset: lst-ctn-kix_yla40c1y0ok7-5 0;
  }

  ol.lst-kix_mjfyf9huakgj-1.start {
    counter-reset: lst-ctn-kix_mjfyf9huakgj-1 0;
  }

  ul.lst-kix_mjfyf9huakgj-4 {
    list-style-type: none;
  }

  .lst-kix_x65qu51ectud-8 > li {
    counter-increment: lst-ctn-kix_x65qu51ectud-8;
  }

  .lst-kix_x65qu51ectud-7 > li {
    counter-increment: lst-ctn-kix_x65qu51ectud-7;
  }

  ol.lst-kix_x65qu51ectud-5.start {
    counter-reset: lst-ctn-kix_x65qu51ectud-5 0;
  }

  ol.lst-kix_mjfyf9huakgj-7.start {
    counter-reset: lst-ctn-kix_mjfyf9huakgj-7 0;
  }

  ol.lst-kix_o7rcp3uhhhar-0 {
    list-style-type: none;
  }

  ol.lst-kix_o7rcp3uhhhar-5.start {
    counter-reset: lst-ctn-kix_o7rcp3uhhhar-5 0;
  }

  ol.lst-kix_o7rcp3uhhhar-1 {
    list-style-type: none;
  }

  ol.lst-kix_o7rcp3uhhhar-2 {
    list-style-type: none;
  }

  ol.lst-kix_o7rcp3uhhhar-3 {
    list-style-type: none;
  }

  ol.lst-kix_o7rcp3uhhhar-5 {
    list-style-type: none;
  }

  ol.lst-kix_o7rcp3uhhhar-6 {
    list-style-type: none;
  }

  ol.lst-kix_yla40c1y0ok7-6 {
    list-style-type: none;
  }

  ol.lst-kix_yla40c1y0ok7-5 {
    list-style-type: none;
  }

  ol.lst-kix_yla40c1y0ok7-3 {
    list-style-type: none;
  }

  ol.lst-kix_yla40c1y0ok7-8 {
    list-style-type: none;
  }

  ol.lst-kix_yla40c1y0ok7-7 {
    list-style-type: none;
  }

  .lst-kix_o7rcp3uhhhar-5 > li {
    counter-increment: lst-ctn-kix_o7rcp3uhhhar-5;
  }

  ol.lst-kix_o7rcp3uhhhar-8.start {
    counter-reset: lst-ctn-kix_o7rcp3uhhhar-8 0;
  }

  ol.lst-kix_yla40c1y0ok7-2 {
    list-style-type: none;
  }

  ol.lst-kix_yla40c1y0ok7-1 {
    list-style-type: none;
  }

  .lst-kix_o7rcp3uhhhar-8 > li {
    counter-increment: lst-ctn-kix_o7rcp3uhhhar-8;
  }

  ol.lst-kix_yla40c1y0ok7-0 {
    list-style-type: none;
  }

  .lst-kix_yla40c1y0ok7-3 > li {
    counter-increment: lst-ctn-kix_yla40c1y0ok7-3;
  }

  .lst-kix_x65qu51ectud-2 > li:before {
    content: '' counter(lst-ctn-kix_x65qu51ectud-2, lower-roman) '. ';
  }

  .lst-kix_x65qu51ectud-3 > li:before {
    content: '' counter(lst-ctn-kix_x65qu51ectud-3, upper-latin) '. ';
  }

  .lst-kix_x65qu51ectud-4 > li:before {
    content: '\0025cf  ';
  }

  .lst-kix_x65qu51ectud-6 > li:before {
    content: '' counter(lst-ctn-kix_x65qu51ectud-6, decimal) '. ';
  }

  ol.lst-kix_yla40c1y0ok7-0.start {
    counter-reset: lst-ctn-kix_yla40c1y0ok7-0 0;
  }

  .lst-kix_mjfyf9huakgj-1 > li:before {
    content: '' counter(lst-ctn-kix_mjfyf9huakgj-1, lower-latin) '. ';
  }

  .lst-kix_yla40c1y0ok7-8 > li {
    counter-increment: lst-ctn-kix_yla40c1y0ok7-8;
  }

  .lst-kix_x65qu51ectud-5 > li:before {
    content: '' counter(lst-ctn-kix_x65qu51ectud-5, decimal) '. ';
  }

  .lst-kix_x65qu51ectud-7 > li:before {
    content: '' counter(lst-ctn-kix_x65qu51ectud-7, decimal) '. ';
  }

  .lst-kix_o7rcp3uhhhar-3 > li {
    counter-increment: lst-ctn-kix_o7rcp3uhhhar-3;
  }

  .lst-kix_mjfyf9huakgj-0 > li:before {
    content: '' counter(lst-ctn-kix_mjfyf9huakgj-0, decimal) '. ';
  }

  ol.lst-kix_yla40c1y0ok7-8.start {
    counter-reset: lst-ctn-kix_yla40c1y0ok7-8 0;
  }

  .lst-kix_x65qu51ectud-5 > li {
    counter-increment: lst-ctn-kix_x65qu51ectud-5;
  }

  .lst-kix_x65qu51ectud-8 > li:before {
    content: '' counter(lst-ctn-kix_x65qu51ectud-8, decimal) '. ';
  }

  .lst-kix_yla40c1y0ok7-2 > li {
    counter-increment: lst-ctn-kix_yla40c1y0ok7-2;
  }

  ol.lst-kix_mjfyf9huakgj-6.start {
    counter-reset: lst-ctn-kix_mjfyf9huakgj-6 0;
  }

  .lst-kix_mjfyf9huakgj-0 > li {
    counter-increment: lst-ctn-kix_mjfyf9huakgj-0;
  }

  .lst-kix_mjfyf9huakgj-3 > li {
    counter-increment: lst-ctn-kix_mjfyf9huakgj-3;
  }

  .lst-kix_mjfyf9huakgj-7 > li:before {
    content: '' counter(lst-ctn-kix_mjfyf9huakgj-7, decimal) '. ';
  }

  ol.lst-kix_mjfyf9huakgj-5.start {
    counter-reset: lst-ctn-kix_mjfyf9huakgj-5 0;
  }

  .lst-kix_mjfyf9huakgj-8 > li:before {
    content: '' counter(lst-ctn-kix_mjfyf9huakgj-8, decimal) '. ';
  }

  .lst-kix_mjfyf9huakgj-6 > li {
    counter-increment: lst-ctn-kix_mjfyf9huakgj-6;
  }

  li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt;
  }

  .lst-kix_mjfyf9huakgj-2 > li:before {
    content: '' counter(lst-ctn-kix_mjfyf9huakgj-2, lower-roman) '. ';
  }

  ol.lst-kix_yla40c1y0ok7-7.start {
    counter-reset: lst-ctn-kix_yla40c1y0ok7-7 0;
  }

  .lst-kix_mjfyf9huakgj-3 > li:before {
    content: '' counter(lst-ctn-kix_mjfyf9huakgj-3, upper-latin) '. ';
  }

  ol.lst-kix_yla40c1y0ok7-1.start {
    counter-reset: lst-ctn-kix_yla40c1y0ok7-1 0;
  }

  .lst-kix_mjfyf9huakgj-4 > li:before {
    content: '\0025cf  ';
  }

  .lst-kix_mjfyf9huakgj-5 > li:before {
    content: '' counter(lst-ctn-kix_mjfyf9huakgj-5, decimal) '. ';
  }

  ul.lst-kix_x65qu51ectud-4 {
    list-style-type: none;
  }

  .lst-kix_mjfyf9huakgj-6 > li:before {
    content: '' counter(lst-ctn-kix_mjfyf9huakgj-6, decimal) '. ';
  }

  ol.lst-kix_o7rcp3uhhhar-3.start {
    counter-reset: lst-ctn-kix_o7rcp3uhhhar-3 0;
  }

  ol {
    margin: 0;
    padding: 0;
  }

  table td,
  table th {
    padding: 0;
  }

  .c0 {
    margin-left: 36pt;
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c13 {
    color: #fff;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: 'HKGrotesk-Regular';
    font-style: normal;
  }

  .c6 {
    padding-top: 0pt;
    padding-left: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c4 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt;
  }

  .c18 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt;
  }

  .c3 {
    color: #fff;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: 'HKGrotesk-Regular';
    font-style: normal;
  }

  .c12 {
    color: #fff;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: 'HKGrotesk-Regular';
    font-style: normal;
  }

  .c9 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c15 {
    color: #fff;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: 'HKGrotesk-Regular';
    font-style: normal;
  }

  .c10 {
    text-decoration-skip-ink: none;
    -webkit-text-decoration-skip: none;
    color: #1155cc;
    text-decoration: underline;
  }

  .c17 {
    background-color: #000;
    max-width: 451.4pt;
    padding: 72pt 72pt 72pt 72pt;
  }

  .c14 {
    color: inherit;
    text-decoration: inherit;
  }

  .c2 {
    padding: 0;
    margin: 0;
  }

  .c8 {
    margin-left: 36pt;
  }

  .c7 {
    margin-left: 108pt;
  }

  .c16 {
    margin-left: 144pt;
  }

  .c1 {
    font-weight: 700;
  }

  .c11 {
    margin-left: 180pt;
  }

  .c5 {
    margin-left: 72pt;
  }

  .title {
    padding-top: 0pt;
    color: #fff;
    font-size: 26pt;
    padding-bottom: 3pt;
    font-family: 'HKGrotesk-Regular';
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .subtitle {
    padding-top: 0pt;
    color: #666666;
    font-size: 15pt;
    padding-bottom: 16pt;
    font-family: 'HKGrotesk-Regular';
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  li {
    color: #fff;
    font-size: 11pt;
    font-family: 'HKGrotesk-Regular';
  }

  p {
    margin: 0;
    color: #fff;
    font-size: 11pt;
    font-family: 'HKGrotesk-Regular';
  }

  h1 {
    padding-top: 20pt;
    color: #fff;
    font-size: 20pt;
    padding-bottom: 6pt;
    font-family: 'HKGrotesk-Regular';
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h2 {
    padding-top: 18pt;
    color: #fff;
    font-size: 16pt;
    padding-bottom: 6pt;
    font-family: 'HKGrotesk-Regular';
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h3 {
    padding-top: 16pt;
    color: #434343;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: 'HKGrotesk-Regular';
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h4 {
    padding-top: 14pt;
    color: #666666;
    font-size: 12pt;
    padding-bottom: 4pt;
    font-family: 'HKGrotesk-Regular';
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h5 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: 'HKGrotesk-Regular';
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h6 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: 'HKGrotesk-Regular';
    line-height: 1.15;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
}
