table {
  border-spacing: 0 1px !important;
  border-collapse: separate !important;
  color: white;
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  text-align: left;
}

.RoyaltyHistory {
  margin-top: 10px;
}

.RoyaltyHistory th {
  background: black;
  color: #ffd600;
  font-size: 18px;
  font-weight: bold;
  margin-top: -10px;
  padding: 20px 0;
  padding-bottom: 0;
}

.RoyaltyHistory td {
  background: black;
  padding: 20px 0 20px 0;
}
