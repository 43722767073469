.container {
  position: relative;
  width: auto;
  top: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container.active {
  z-index: 11;
}

.slider {
  position: relative;
  width: 85vw;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 3px;
  height: 5px;
}

.slider__track {
  background-color: transparent;
  width: 100%;
  z-index: 1;
}

.slider__range {
  background-color: transparent;
  z-index: 1;
}

.slider__left-value,
.slider__right-value {
  color: #e6dede;
  font-size: 12px;
  margin-top: 20px;
}

.slider__left-value {
  left: 6px;
}

.slider__right-value {
  right: -4px;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 90vw;
  outline: none;
}

.thumb--zindex-1 {
  z-index: 1;
}

.thumb--zindex-2 {
  z-index: 2;
}

.thumb--zindex-3 {
  z-index: 3;
}

.thumb--zindex-4 {
  z-index: 4;
}

.thumb--zindex-5 {
  z-index: 5;
}

.left-thumb-adjust {
  margin-left: -22px;
}

.right-thumb-adjust {
  margin-left: 22px;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: #ffd600;
  background-clip: padding-box;
  background-image: url(/src/common/assets/slider-inner.svg);
  background-repeat: no-repeat;
  background-position-y: 50%;
  background-position-x: 50%;
  cursor: pointer;
  height: 132px;
  width: 50px;
  margin-top: 10px;
  pointer-events: all;
  position: relative;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: #ffd600;
  background-clip: padding-box;
  background-image: url(/src/common/assets/slider-inner.svg);
  background-repeat: no-repeat;
  background-position-y: 50%;
  background-position-x: 50%;
  cursor: pointer;
  height: 132px;
  width: 50px;
  margin-top: 10px;
  pointer-events: all;
  position: relative;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
}

@media (min-width: 576px) {
  .thumb {
    width: 90vw;
  }

  .slider {
    width: 90vw;
  }
}

@media (min-width: 768px) {
  .left-thumb-adjust {
    margin-left: -40px;
  }

  .right-thumb-adjust {
    margin-right: -40px;
  }

  /* For Chrome browsers */
  .thumb::-webkit-slider-thumb {
    height: 180px;
    margin-top: 67px;
  }

  /* For Firefox browsers */
  .thumb::-moz-range-thumb {
    height: 180px;
    margin-top: 67px;
  }
}

@media (min-width: 1200px) {
  .container {
    position: relative;
    width: auto;
    top: 86px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .slider {
    position: relative;
    width: 89vw;
  }

  .left-thumb-adjust {
    margin-left: -50px;
  }

  .right-thumb-adjust {
    margin-right: -45px;
  }

  /* For Chrome browsers */
  .thumb::-webkit-slider-thumb {
    margin-top: 18px;
  }

  /* For Firefox browsers */
  .thumb::-moz-range-thumb {
    margin-top: 4px;
  }
}

@media (min-width: 1920px) {
  .left-thumb-adjust {
    margin-left: -120px;
  }

  .right-thumb-adjust {
    margin-right: -120px;
  }

  /* For Chrome browsers */
  .thumb::-webkit-slider-thumb {
    margin-top: 18px;
  }

  /* For Firefox browsers */
  .thumb::-moz-range-thumb {
    margin-top: 18px;
  }
}

/* !! Disabled for supporting only last version of Safari */
/* For Safari only*/
/* @media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .left-thumb-adjust {
      margin-left: 0px;
      margin-top: 0;
    }

    .right-thumb-adjust {
      margin-left: 10px;
      margin-top: 0;
    }

    @media (min-width: 768px) {
      .left-thumb-adjust {
        margin-left: -20px;
      }

      .right-thumb-adjust {
        margin-left: 20px;
      }
    }

    @media (min-width: 1200px) {
      .left-thumb-adjust {
        margin-left: -30px;
      }

      .right-thumb-adjust {
        margin-left: 30px;
      }
    }

    @media (min-width: 1920px) {
      .left-thumb-adjust {
        margin-left: -60px;
      }

      .right-thumb-adjust {
        margin-left: 60px;
      }
    }
  }
} */
