.risks {
  font-family: 'HKGrotesk-Regular';

  p {
    display: block !important;
    margin-block-start: 0.5em !important;
    margin-block-end: 0.5em !important;
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;

    line-height: 18px !important;
    padding-left: -15px !important;
  }
  
  ul.lst-kix_3mgy6rkshbwg-7 {
    list-style-type: none;
  }

  .lst-kix_3mgy6rkshbwg-0 > li:before {
    content: '\0025cf  ';
  }

  li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt;
  }

  .lst-kix_3mgy6rkshbwg-6 > li:before {
    content: '\0025cf  ';
  }

  .lst-kix_3mgy6rkshbwg-5 > li:before {
    content: '\0025a0  ';
  }

  .lst-kix_3mgy6rkshbwg-7 > li:before {
    content: '\0025cb  ';
  }

  .lst-kix_3mgy6rkshbwg-4 > li:before {
    content: '\0025cb  ';
  }

  .lst-kix_3mgy6rkshbwg-8 > li:before {
    content: '\0025a0  ';
  }

  ul.lst-kix_3mgy6rkshbwg-0 {
    list-style-type: none;
  }

  ul.lst-kix_3mgy6rkshbwg-2 {
    list-style-type: none;
  }

  .lst-kix_3mgy6rkshbwg-1 > li:before {
    content: '\0025cb  ';
  }

  ul.lst-kix_3mgy6rkshbwg-1 {
    list-style-type: none;
  }

  .lst-kix_3mgy6rkshbwg-3 > li:before {
    content: '\0025cf  ';
  }

  ul.lst-kix_3mgy6rkshbwg-4 {
    list-style-type: none;
  }

  ul.lst-kix_3mgy6rkshbwg-3 {
    list-style-type: none;
  }

  ul.lst-kix_3mgy6rkshbwg-6 {
    list-style-type: none;
  }

  .lst-kix_3mgy6rkshbwg-2 > li:before {
    content: '\0025a0  ';
  }

  ul.lst-kix_3mgy6rkshbwg-5 {
    list-style-type: none;
  }

  ol {
    margin: 0;
    padding: 0;
  }

  table td,
  table th {
    padding: 0;
  }

  .c0 {
    border-right-style: solid;
    padding-top: 0pt;
    border-top-width: 0pt;
    border-bottom-color: #e5e7eb;
    border-right-width: 0pt;
    padding-left: 0pt;
    border-left-color: #e5e7eb;
    padding-bottom: 0pt;
    line-height: 1;
    border-right-color: #e5e7eb;
    border-left-width: 0pt;
    border-top-style: solid;
    border-left-style: solid;
    border-bottom-width: 0pt;
    border-top-color: #e5e7eb;
    border-bottom-style: solid;
    orphans: 2;
    widows: 2;
    text-align: left;
    padding-right: 0pt;
  }

  .c8 {
    color: #fff;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: 'HKGrotesk-Regular';
    font-style: normal;
  }

  .c7 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt;
  }

  .c1 {
    color: #fff;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: 'HKGrotesk-Regular';
    font-style: normal;
  }

  .c12 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt;
  }

  .c10 {
    padding-top: 7.5pt;
    padding-bottom: 7.5pt;
    line-height: 1;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .c2 {
    color: #fff;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: 'HKGrotesk-Regular';
    font-style: normal;
  }

  .c6 {
    background-color: #000;
    max-width: 451.4pt;
    padding: 72pt 72pt 72pt 72pt;
  }

  .c5 {
    font-family: 'Calibri';
    font-weight: 700;
  }

  .c11 {
    padding: 0;
    margin: 0;
  }

  .c4 {
    margin-left: 36pt;
  }

  .c9 {
    font-weight: 700;
  }

  .c3 {
    font-size: 12pt;
  }

  .title {
    padding-top: 0pt;
    color: #fff;
    font-size: 26pt;
    padding-bottom: 3pt;
    font-family: 'HKGrotesk-Regular';
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  .subtitle {
    padding-top: 0pt;
    color: #666666;
    font-size: 15pt;
    padding-bottom: 16pt;
    font-family: 'HKGrotesk-Regular';
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  li {
    color: #fff;
    font-size: 11pt;
    font-family: 'HKGrotesk-Regular';
  }

  p {
    margin: 0;
    color: #fff;
    font-size: 11pt;
    font-family: 'HKGrotesk-Regular';
  }

  h1 {
    padding-top: 20pt;
    color: #fff;
    font-size: 20pt;
    padding-bottom: 6pt;
    font-family: 'HKGrotesk-Regular';
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h2 {
    padding-top: 18pt;
    color: #fff;
    font-size: 16pt;
    padding-bottom: 6pt;
    font-family: 'HKGrotesk-Regular';
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h3 {
    padding-top: 16pt;
    color: #434343;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: 'HKGrotesk-Regular';
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h4 {
    padding-top: 14pt;
    color: #666666;
    font-size: 12pt;
    padding-bottom: 4pt;
    font-family: 'HKGrotesk-Regular';
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h5 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: 'HKGrotesk-Regular';
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left;
  }

  h6 {
    padding-top: 12pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 4pt;
    font-family: 'HKGrotesk-Regular';
    line-height: 1.15;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left;
  }
}
