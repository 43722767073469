.toaster-notify  {
  width: 100%;
  left: 0;
  top: -16vh;

  @media (min-width: 768px) {
    left: -50px;
    width: 450px;
  }
}
