table {
  border-spacing: 0 1px !important;
  border-collapse: separate !important;
  color: white;
  width: 100%;
  text-align: left;
  padding: 30px;
}

.Transations {
  margin-top: 10px;
}

.Transations th {
  background: black;
  color: #ffd600;
  font-size: 18px;
  font-weight: bold;
  margin-top: -10px;
  padding: 20px 0;
  padding-bottom: 0;
}

.Transations td {
  background: black;
  padding: 20px 0 20px 0;
}
